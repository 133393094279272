import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, Space, Table, Typography, Switch } from "antd";
import { useSelector } from "react-redux";
import _ from "underscore";
import { ArrowUpOutlined } from "@ant-design/icons";
import DateStatistic from "../tableComponents/dateStatistic";
import { leadsDataSelector } from "../../storage/reducers/leads.reducer";
import { userSelector } from "../../storage/reducers/user.reducer";
import Lead from "../../types/Lead";

const { Text, Link } = Typography;

interface LeadsTableProps {}

const LeadsTable: React.FC<LeadsTableProps> = () => {
  const leads = useSelector(leadsDataSelector);
  const user = useSelector(userSelector);
  const [sync, setSync] = useState(true);

  const [leadsToShow, setLeadsToShow] = React.useState<Lead[]>([]);

  useEffect(() => {
    if (leads) {
      const filteredLeads = _.filter(leads, (lead) => {
        let isSynced = true;
        const nextSync = lead.custom_fields["Next Sync"];

        if (nextSync && sync) {
          isSynced = moment() >= moment.unix(nextSync / 1000);
        }

        return (
          (lead.assignee?.id == user.data?.clickup_id || user.data?.position === 'SU') &&
          isSynced &&
          !["Lost", "Complete"].includes(lead.status)
        );
      });

      console.log(filteredLeads, leads);

      setLeadsToShow(filteredLeads);
    }
  }, [leads, sync]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "170px",
      render: (name, record) => {
        return (
          <Space>
            <Link
              target="_blank"
              strong
              href={`https://app.clickup.com/2479620/t/${record.id}`}
            >
              {name}
            </Link>
            {record.external_link ? (
              <Link
                target="_blank"
                underline
                style={{ fontSize: "12px" }}
                href={record.external_link}
              >
                <ArrowUpOutlined />
              </Link>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: "Timeline",
      dataIndex: "time",
      key: "time",
      width: "130px",
      render: (time, record) => {
        console.log('A: record', record);
        
       return <DateStatistic record={record} />
      },
    },
    {
      title: "Estimate",
      key: "estimate",
      dataIndex: "estimate",
      width: "110px",
      render: (estimate) => {
        let resultString = "";
        if (estimate.value) {
          resultString =
            String(estimate.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")) +
            " " +
            String(estimate.type_config.currency_type);
        }
        return resultString;
      },
    },
  ];

  return (
    <>
      {leadsToShow?.length ? (
        <Card size="small" title={"Sales"}>
          <Space>
            <Text strong>Need Sync</Text>
            <Switch checked={sync} onChange={() => setSync(!sync)} />
          </Space>
          <Table
            size="small"
            pagination={false}
            columns={columns}
            dataSource={leadsToShow}
            rowKey="id"
            scroll={{ y: 240, x: 800 }}
            summary={(pageData) => {
              let totalEstimates = 0;

              pageData.forEach((item) => {
                if (item.estimate?.value)
                  totalEstimates += parseFloat(String(item.estimate?.value));
              });
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      Summary <Text strong>({pageData.length})</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Text strong>
                        {String(totalEstimates).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        ) + " USD"}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Card>
      ) : null}
    </>
  );
};

export { LeadsTable };
