import { Card, Space, Table, Typography, Tag, Col, Row, Button, Popover } from 'antd';
import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import moment from 'moment';
import getStatusColor from '../../helpers/getStatusColor';
import DateStatistic from '../tableComponents/dateStatistic';
import IssuePopover from '../tableComponents/issuePopover';
import timeStatistic from '../tableComponents/timeStatistic';
import Text from 'antd/lib/typography/Text';
import RatioBar from '../tableComponents/ratioBar';
import { useEffect } from 'react';
import _ from "underscore";

const { Link } = Typography;

interface Column {
  [key: string]: string;
}

const getMilestoneRatioDetails = (record) => {
  return (
    <Space direction="vertical" style={{ width: "300px" }} size="small">
      {record?.spent?.users
        ? _.map(record.spent.users, (user) => {
          if (user.position !== 'PM') return (
            <Row key={user.id}>
              <Col span={12}>
                <Text strong>{user.name?.split(" ")[0]}</Text>{" "}
                <Text code>{user.position}</Text>
              </Col>
              <Col span={6}>
                {user.hours ? `${Math.round(user.hours)} hrs` : "-"}
              </Col>
            </Row>
          )
        })
        : null}
    </Space>
  )
}

const getColumns = (arrColumn: string[]) => {
  const commonColumns = {
    milestoneName: {
      order: 0,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return (
          <>
            {!(record.issues && record.issues?.length) ? (
              <CheckCircleTwoTone twoToneColor='#52c41a' />
            ) : (
              <IssuePopover record={record}></IssuePopover>
            )}{' '}
            <Space size={-2} direction='vertical'>
              <Link
                target='_blank'
                href={`https://app.clickup.com/2479620/v/li/${record.listRef}`}
              >
                {name}
              </Link>
              <Link
                strong
                underline
                target='_blank'
                href={`https://app.clickup.com/2479620/v/li/${record.listRef}`}
              >
                {record.projectName}
              </Link>
            </Space>
          </>
        );
      },
    },
    //milestoneTimeline
    milestoneTimeline: {
      order: 2,
      title: 'Timeline',
      dataIndex: 'time',
      key: 'time',
      render: (time, record) => <DateStatistic record={record} />,
    },
    //milestoneStatus
    milestoneStatus: {
      order: 3,
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status, record) => {
        return (
          <>
            {status ? (
              <Tag color={record.statusColor || getStatusColor(status)} key={status}>
                {' '}
                {status.toUpperCase()}{' '}
              </Tag>
            ) : null}
          </>
        );
      },
    },
    milestoneET: {
      title: 'ET',
      dataIndex: 'et',
      key: 'et',
      render: (et, record) => {
        return <> {record.ET ? record.ET : 0}</>;
      },
    },
    milestoneEP: {   
      title: 'EP',
      dataIndex: 'EP',
      key: 'ep',
      render: (ep) => <div>{ep ? Math.round(ep * 100) / 100 : ''}</div>,
    },
    milestoneRatio: {
      title: 'Ratio',
      dataIndex: 'ratio',
      key: 'ratio',
      width: '180px',
      render: (et, record) => (
        <Popover
            content={getMilestoneRatioDetails(record)}
            title="Ratio"
            trigger="hover"
          >
          <div></div>
          <RatioBar
            estimate={record.ET}
            spent={record.spent.dirty}
            type='milestone'
          />
        </Popover>
      ),
    },
    milestoneSpent: {
      title: 'Spent',
      dataIndex: 'spent',
      key: 'spent',
      render: (et, record) => {
        return <> {record.spent?.hours ? Math.round(record.spent.hours) : 0}</>;
      },
    },
    milestoneCOM: {
      title: 'COM',
      dataIndex: 'com',
      key: 'com',
      width: '100px',
      render: (et, record) =>
        record.spent.dirty
          ? `${Math.round((record.ET / record.spent.dirty) * 100)}%`
          : '',
    },
    milestoneCleanHrs: {
      title: 'Clean Hrs',
      dataIndex: 'clean',
      key: 'clean',
      width: '100px',
      render: (et, record) => Math.round(record.spent.hours),
    },
    milestoneQaTasks: {
      title: 'QA tasks',
      dataIndex: 'tasks',
      key: 'tasks',
      render: (tasks) => tasks.length,
    },

    //tasks
    taskName: {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return (
          <Space size={-2} direction='vertical'>
            <Link
              target='_blank'
              href={`https://app.clickup.com/t/${record.id}`}
            >
              {!record.issues?.length ? (
                <CheckCircleTwoTone twoToneColor='#52c41a' />
              ) : (
                <IssuePopover record={record}></IssuePopover>
              )}{' '}
              {`${name.substring(0, 50)}...`}
            </Link>
            <Link
              strong
              underline
              target='_blank'
              href={`https://app.clickup.com/2479620/v/li/${record.listRef}`}
            >
              {record.projectName}
            </Link>
          </Space>
        );
      },
    },
    taskDescription: {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      // maxWidth: '180',
      render: (description) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {description ? `${description.substring(0, 120)}...` : ''}
        </div>
      ),
    },
    taskTimeline: {
      title: 'Timeline',
      dataIndex: 'time',
      key: 'time',
      render: (time, record) => timeStatistic(record),
    },
    taskRatio: {
      title: 'Ratio',
      dataIndex: 'ratio',
      key: 'ratio',
      width: '180px',
      render: (et, record) => (
        <RatioBar
          estimate={record.estimate ? record.estimate : 0}
          spent={record.spent ? record.spent : 0}
          type='task'
        />
      ),
    },
    taskStatus: {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status, record) => {
        return (
          <>
            {record.status ? (
              <Tag color={record.status.color} key={record.status.status}>
                {' '}
                {record.status.status.toUpperCase()}{' '}
              </Tag>
            ) : null}
          </>
        );
      },
    },
    taskAssignees: {
      title: 'Assignee',
      dataIndex: 'assignees',
      key: 'assignees',
      render: (assignees, record) =>
        assignees?.clickup?.username ? assignees.clickup.username : '',
    },

    //members
    memberName: {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      render: (username, record) => {
        return (
          <>
            {!(record.issues && record.issues?.length) ? (
              <CheckCircleTwoTone twoToneColor='#52c41a' />
            ) : (
              <IssuePopover record={record}></IssuePopover>
            )}{' '}
            {username}
          </>
        );
      },
    },
    memberRole: {
      title: 'Role',
      dataIndex: 'position',
      key: 'position',
    },
    // memberActions: {
    //   title: 'Actions',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (id, record) => {       
    //     return (
    //       <Button type='primary' onClick={() => handleSwitchUser(record)}>
    //         View
    //       </Button>
    //     );
    //   },
    // },
    amountOfMilestones: {
      title: 'Milestones',
      dataIndex: 'milestones',
      key: 'milestones',
      render: (milestones, record) =>
        record.activeMilestones ? record.activeMilestones.length : 0,
    },
    amountOfOpenTasks: {
      title: 'Open Tasks',
      dataIndex: 'activeTasks',
      key: 'activeTasks',
      render: (activeTasks, record) =>
        record.activeTasks ? record.activeTasks.length : 0,
    },
    amountOfReviewTasks: {
      title: 'Tasks on Review',
      dataIndex: 'onReview',
      key: 'onReview',
      render: (onReview, record) =>
        record.onReview ? record.onReview.length : 0,
    },
    amountOfTasksToClose: {
      title: 'Require Close',
      dataIndex: 'requireClose',
      key: 'requireClose',
      render: (requireClose, record) =>
        record.tasksToClose ? record.tasksToClose.length : 0,
    },
    logs: {
      title: 'Logs',
      children: [
        {
          title: 'T',
          dataIndex: 'today',
          key: 'today',
          render: (today, record) =>
            record.timeLogs?.today
              ? Math.round(
                  moment.duration(record.timeLogs.today.duration).asHours()*10
                )/10
              : 0,
        },
        {
          title: 'Y',
          dataIndex: 'yesterday',
          key: 'yesterday',
          render: (today, record) =>
            record.timeLogs?.yesterday
              ? Math.round(
                  moment.duration(record.timeLogs.yesterday.duration).asHours()*10
                )/10
              : 0,
        },
        {
          title: 'P',
          dataIndex: 'curPeriod',
          key: 'curPeriod',
          render: (today, record) => {
            return record.timelogs?.thisPeriod
              ? Math.round(
                  moment.duration(record.timelogs.thisPeriod.duration).asHours()*10
                )/10
              : 0;
          },
        },
      ],
    },
  };

  const columns: Column[] = [];

  arrColumn.forEach((el) => {
    const column = commonColumns[el];
    if (column) {
      columns.push(column);
    }
  });

  return columns;
};

export default getColumns;
