import React, { useEffect } from 'react';
import { Card, Space, Table, Typography, Tag, Col, Row } from 'antd';
import _ from 'underscore';
import getColumns from './getColumns';
import {
  MILESTONE_ET,
  MILESTONE_NAME,
  MILESTONE_STATUS,
  MILESTONE_TIMELINE,
  MILESTONE_SPENT,
  TASK_NAME,
  TASK_STATUS,
  TASK_TIMELINE,
  TASK_RATIO,
} from '../../constants/columnConstants';
import Timers from './timers';
import Text from 'antd/lib/typography/Text';
import Milestone from '../../types/Milestone';
import Task from '../../types/Task';
import { isDev } from '../../helpers';

interface Tasks {
  [index: number]: Task;
}

interface TimersData {
  title: string;
  duration: number;
}
interface TimersProps {
  today: TimersData | null;
  yesterday: TimersData | null;
  thisPeriod: TimersData | null;
  lastPeriod: TimersData | null;
  thisWeek: TimersData | null;
}

interface Issues {
  [index: number]: string;
}

interface Record {
  id: string;
  activeTasks: any | Tasks;
  issues: any | Issues;
  timelogs: TimersProps;  
  activeMilestones: any | Milestones;  
  custom_fields: any;
  tasksToClose: Tasks
}
interface Milestones {
  [index: number]: Milestone;
}

const expandedCommonTable = (record: Record, position: string) => {

  console.log('record', record);
  console.log('position', position);
  
  const data = {
    DEV: {
      title1: 'Active Milestones',
      title2: 'Tasks',
      title3: 'Timers',
      title4: 'Warnings',
      columns1: getColumns([
        MILESTONE_NAME,
        MILESTONE_TIMELINE,
        MILESTONE_STATUS,
      ]),
      columns2: getColumns([TASK_NAME, TASK_RATIO, TASK_STATUS]),
      dataSource1: record.activeMilestones,
      dataSource2: record.activeTasks,
      dataSource3: record.issues,
    },
    PM: {
      title1: 'Active Milestones',
      title2: 'Require Close',
      columns1: getColumns([
        MILESTONE_NAME,
        MILESTONE_TIMELINE,
        MILESTONE_ET,
        MILESTONE_SPENT,
        MILESTONE_STATUS,
      ]),
      columns2: getColumns([TASK_NAME, TASK_RATIO, TASK_STATUS]),
      dataSource1: record.activeMilestones,
      dataSource2: record.tasksToClose,
    },
  };
  
  return (
    <>
      <Row>
        <Col span={12}>
          {' '}
          <Card size='small' title={data[position].title1}>
            <Table
              size='small'
              pagination={false}
              columns={data[position].columns1}
              dataSource={data[position].dataSource1}
              rowKey='id'
            />
          </Card>
        </Col>
        <Col span={12}>
          {' '}
          <Card size='small' title={data[position].title2}>
            <Table
              size='small'
              pagination={false}
              columns={data[position].columns2}
              dataSource={data[position].dataSource2}
              rowKey='id'
            />
          </Card>
        </Col>
      </Row>
      {isDev(position) ? (
        <Row>
          <Col span={12}>
            {' '}
            <Card size='small' title={data[position].title3}>
              <Timers
                {...record.timelogs}               
              ></Timers>
            </Card>
          </Col>
          <Col span={12}>
            {' '}
            <Card size='small' title={data[position].title4}>
              <Space direction='vertical'>
                {record.issues?.length
                  ? record.issues.map((iss) => (
                      <>
                        <Text key={iss} type='warning'>
                          {iss}
                        </Text>
                      </>
                    ))
                  : null}
              </Space>
            </Card>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default expandedCommonTable;
