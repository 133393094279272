import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import Lead from '../../types/Lead';

export interface LeadsStateData {
    data?: Lead[];
}

const initialState: LeadsStateData = {
  data: undefined,
};

export const LeadsSlice = createSlice({
  name: 'leads',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
  },
});

export const $leads_actions = LeadsSlice.actions;

export const leadsSelector = (state: RootState): LeadsStateData => state.leads;

export const leadsDataSelector = createSelector(
  leadsSelector,
  (state) => state.data
);

export default LeadsSlice.reducer;
