import React, { useEffect, useState } from 'react';
import { Space, Table, Typography } from 'antd';
import { milestonesActiveSelector } from '../../storage/reducers/milestones.reducer';
import { tasksDataSelector } from '../../storage/reducers/tasks.reducer';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { CheckCircleTwoTone } from '@ant-design/icons';
import expandedTasksTable from './expandedTasksTable';
import IssuePopover from '../tableComponents/issuePopover';
import getColumns from '../common/getColumns';
import {
  MILESTONE_CLEAN_HRS,
  MILESTONE_COM,
  MILESTONE_RATIO,
  MILESTONE_STATUS,
  MILESTONE_TIMELINE,
} from '../../constants/columnConstants';

const { Text, Link } = Typography;

interface MilestonesTableProps {
  milestonesIdArray: string[];
  toClose?: boolean;
  isPM?: boolean
}

const ExpandedMilestonesTable: React.FC<MilestonesTableProps> = ({
  milestonesIdArray,
  isPM
}) => {
  const milestones: any = useSelector(milestonesActiveSelector);
  const tasks: any = useSelector(tasksDataSelector);

  const [PMs, setPMs] = useState([]);

  const [milestonesToShow, setMilestonesToShow] =
    React.useState<any>(milestones);

  useEffect(() => {
    const PMsList: any = [];

    const filteredMilestones = milestones;

    const milestonesList = _.map(filteredMilestones, (el) => {
      if (el.PM && el.PM.length) {
        if (!_.find(PMsList, (pm) => pm.value == el.PM[0].id)) {
          PMsList.push({
            text: el.PM[0].username,
            value: el.PM[0].id,
          });
        }
      }

      const newTasks = _.map(el.tasks, (task) =>
        tasks && tasks[task] ? tasks[task] : {}
      );

      return { ...el, tasks: newTasks };
    });

    setPMs(PMsList);
    setMilestonesToShow(
      milestonesList.filter((el) => milestonesIdArray.includes(el.id))
    );
  }, [milestones, tasks]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (name, record) => {
        return (
          <Space size={-4} direction='vertical'>
            {record.listRef ? (
              <Link
                target='_blank'
                strong
                href={`https://app.clickup.com/2479620/v/li/${record.listRef}`}
              >
                {!(record.issues && record.issues?.length) ? (
                  <CheckCircleTwoTone twoToneColor='#52c41a' />
                ) : (
                  <IssuePopover record={record}></IssuePopover>
                )}{' '}
                {name}
              </Link>
            ) : (
              <Text strong>
                {!(record.issues && record.issues?.length) ? (
                  <CheckCircleTwoTone twoToneColor='#52c41a' />
                ) : (
                  <IssuePopover record={record}></IssuePopover>
                )}{' '}
                {name}
              </Text>
            )}
          </Space>
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: 'PM',
      dataIndex: 'PM',
      key: 'pm',
      filters: PMs,
      width: '130px',
      onFilter: (value, record) => record.PM[0].id === value,
      render: (pm) => (
        <div>{pm && pm.length && pm[0].username.split(' ')[0]}</div>
      ),
    },
    ...getColumns([
      MILESTONE_TIMELINE,
      MILESTONE_RATIO,
      MILESTONE_CLEAN_HRS,
      MILESTONE_COM,
    ]),
    {
      title: 'Spent USD',
      dataIndex: 'spentUsd',
      key: 'spentUsd',
      align: 'right',
      width: '100px',
      hidden: isPM,
      render: (et, record) => {
        record.spent.price ? (
          <Text style={{ color: '#0231E8' }}>
            {'$ ' + Intl.NumberFormat().format(Math.round(record.spent.price))}
          </Text>
        ) : (
          ''
        );
      },
    },
    {
      title: 'E. USD',
      dataIndex: 'EP',
      key: 'ep',
      align: 'right',
      hidden: isPM,
      render: (ep) => (
        <Text style={{ color: '#0231E8' }}>
          {ep ? '$ ' + Intl.NumberFormat().format(Math.round(ep)) : null}
        </Text>
      ),
    },
    {
      title: 'GPM$ (%)',
      dataIndex: 'EP',
      key: 'ep',
      align: 'right',
      hidden: isPM,
      render: (ep, record) => (
        <Text style={{ color: '#0231E8' }}>
          {ep && record.spent.price
            ? `$ ${Intl.NumberFormat().format(
                Math.round(ep - record.spent.price)
              )}`
            : null}
        </Text>
      ),
    },
    ...getColumns([MILESTONE_STATUS]),
    // @ts-ignore TODO: fix this
  ].filter((item) => !item.hidden);

  return (
    <>
      <Table
        size='small'
        pagination={false}
        columns={columns}
        scroll={{ y: 350 }}
        expandable={{
          expandedRowRender: (record) => expandedTasksTable(record),
          rowExpandable: (record) => record.tasks.length,
        }}
        dataSource={milestonesToShow}
        rowKey='id'
      />
    </>
  );
};

export { ExpandedMilestonesTable };
