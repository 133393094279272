import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col } from 'antd';
import { userDataSelector } from '../../storage/reducers/user.reducer';
import PieChart from '../../components/sales/pieChart';

interface SalesProps {}

const SalesDashboard: React.FC<SalesProps> = () => {
  const user = useSelector(userDataSelector);

  return (
    <>
      {(user?.position === 'SU' || user?.position === 'Delivery Manager') ? (
        <Card size='small' title={'Sales Dashboard'}>
          <Row gutter={[16, 16]}>
            <Col span={8}>             
              <PieChart title='This week'></PieChart>
            </Col>
            <Col span={8}>              
              <PieChart title='This period'></PieChart>
            </Col>
            <Col span={8}>             
              <PieChart title='Last period'></PieChart>
            </Col>
          </Row>
        </Card>
      ) : null}
    </>
  );
};

export { SalesDashboard };
