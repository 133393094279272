import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Card,
  Space,
  Table,
  Typography,
  Popover,
  Form,
  Radio,
  Switch,
  Row,
  Col,
  Segmented,
  DatePicker,
} from "antd";

import { milestonesArraySelector } from "../../storage/reducers/milestones.reducer";
import { projectsDataSelector } from "../../storage/reducers/projects.reducer";
import { tasksDataSelector } from "../../storage/reducers/tasks.reducer";
import { useSelector } from "react-redux";
import _ from "underscore";
import { CheckCircleTwoTone, FolderOutlined, ForkOutlined } from "@ant-design/icons";
import expandedTasksTable from "./expandedTasksTable";
import IssuePopover from "../tableComponents/issuePopover";
import {
  MILESTONE_CLEAN_HRS,
  MILESTONE_COM,
  MILESTONE_RATIO,
  MILESTONE_STATUS,
  MILESTONE_TIMELINE,
} from "../../constants/columnConstants";
import getColumns from "../common/getColumns";
import { getTimeConstants } from "../../helpers/timeLogs";

const { Text, Link } = Typography;
const { RangePicker } = DatePicker;

const getSpentDetails = (record) => {
  return (
    <Space direction="vertical" style={{ width: "300px" }} size="small">
      {record?.spent?.users
        ? _.map(record.spent.users, (user) => (
            <Row key={user.id}>
              <Col span={12}>
                <Text strong>{user.name?.split(" ")[0]}</Text>{" "}
                <Text code>{user.position}</Text>
              </Col>
              <Col span={6}>
                {user.hours ? `${Math.round(user.hours)} hrs` : "-"}
              </Col>
              <Col span={6}>
                <Text style={{ color: "red" }}>
                  {user.price ? `${Math.round(user.price)} $` : "-"}
                </Text>
              </Col>
            </Row>
          ))
        : null}
    </Space>
  );
};

const getGlobalCOMDetails = (totalEstimate, totalSpent) => {
  return (
    <Space direction="vertical" style={{ width: "300px" }} size="small">
      <Row key={1}>
        <Col span={12}>Estimate</Col>
        <Col span={6}>
          <Text>
            {totalEstimate ? `${Math.round(totalEstimate)} hrs` : "-"}
          </Text>
        </Col>
      </Row>
      <Row key={2}>
        <Col span={12}>Spent</Col>
        <Col span={6}>
          <Text>{totalSpent ? `${Math.round(totalSpent)} hrs` : "-"}</Text>
        </Col>
      </Row>
    </Space>
  );
};

interface MilestonesTableProps {
  title: string;
  type: string;
  isPM?: boolean
}

const MilestonesTableExtended: React.FC<MilestonesTableProps> = ({
  title,
  type = "",
  isPM
}) => {
  const milestones: any = useSelector(milestonesArraySelector);
  const projects: any = useSelector(projectsDataSelector);
  const tasks: any = useSelector(tasksDataSelector);
  const [flow, setFlow] = useState("");
  const [sync, setSync] = useState(true);
  const [isDataPicker, setDataPicker] = useState<boolean>(false);

  const [PMs, setPMs] = useState([]);

  const [milestonesToShow, setMilestonesToShow] =
    React.useState<any>(milestones);
  const [closedMilestones, setClosedMilestones] =
    React.useState<any>(milestones);

  useEffect(() => {
    const PMsList: any = [];
    let milestonesArr = [];
    if (milestones) {
      milestonesArr = Object.values(milestones);
    }

    let filteredMilestones = milestonesArr;

    if (type === "closed") {
      filteredMilestones = _.filter(milestonesArr, (mile) =>
        _.contains(mile.flowTo, "closed")
      );

      setClosedMilestones(filteredMilestones);
    }
    if (type === "to_close") {
      filteredMilestones = _.filter(milestonesArr, (mile) =>
        _.contains(mile.flowTo, "to_close")
      );
    }
    if (type === "active") {
      filteredMilestones = _.union(
        _.filter(milestonesArr, (mile) => _.contains(mile.flowTo, "active")),
        _.filter(milestonesArr, (mile) => _.contains(mile.flowTo, "to_start"))
      );
    }
    if (flow)
      filteredMilestones = _.filter(filteredMilestones, (mile) =>
        _.contains(mile.flowTo, flow)
      );
    if (sync)
      filteredMilestones = _.filter(filteredMilestones, (mile) =>
        !mile.nextSync ? true : moment() >= moment.unix(mile.nextSync / 1000)
      );

    const milestonesList = _.map(filteredMilestones, (el) => {
      if (el.PM && el.PM.length) {
        if (!_.find(PMsList, (pm) => pm.value == el.PM[0].id)) {
          PMsList.push({
            text: el.PM[0].username,
            value: el.PM[0].id,
          });
        }
      }

      const newTasks = _.map(el.tasks, (task) =>
        tasks && tasks[task] ? tasks[task] : {}
      );

      return { ...el, tasks: newTasks };
    });

    setPMs(PMsList);

    setMilestonesToShow(milestonesList);
  }, [milestones, tasks, flow, sync]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (name, record) => {
        return (
          <Space size={-4} direction="vertical">
            {record.listRef ? (
              <Link
                target="_blank"
                strong
                href={record.type === 1 ?
                  `https://app.clickup.com/2479620/v/li/${record.listRef}` :
                  `https://app.clickup.com/2479620/v/f/${record.listRef}`
                }
                style={{
                  display: 'flex'
                }}
              >
                <Row>
                  <Space>
                    <Col>
                      {record.type ? (
                        <ForkOutlined />
                      ) : (
                        <FolderOutlined />
                      )}{" "}
                    </Col>
                    {name}
                    <Col>
                      {!(record.issues && record.issues?.length) ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ) : (
                        <IssuePopover record={record}></IssuePopover>
                      )}{" "}

                    </Col>
                  </Space>
                </Row>
              </Link>
            ) : (
              <Text strong>
                <Space>
                  {name}
                  {!(record.issues && record.issues?.length) ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <IssuePopover record={record}></IssuePopover>
                  )}{" "}
                </Space>
              </Text>
            )}
            <Link
              target="_blank"
              underline
              style={{ fontSize: "12px" }}
              href={`https://app.clickup.com/t/${record.id}`}
            >
              <Text type="secondary">{projects[record.parent]?.name}</Text>
            </Link>
          </Space>
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: "PM",
      dataIndex: "PM",
      key: "pm",
      filters: PMs,
      width: "130px",
      onFilter: (value, record) => record.PM[0].id === value,
      render: (pm) => (
        <div>{pm && pm.length && pm[0].username.split(" ")[0]}</div>
      ),
    },
    ...getColumns([
      MILESTONE_TIMELINE,
      MILESTONE_RATIO,
      MILESTONE_CLEAN_HRS,
      MILESTONE_COM,
    ]),
    {
      title: "Spent USD",
      dataIndex: "spentUsd",
      key: "spentUsd",
      align: "right",
      width: "100px",
      hidden: isPM,
      render: (et, record) =>
        record.spent.price ? (
          <Popover
            content={getSpentDetails(record)}
            title="Spent"
            trigger="hover"
          >
            <Text style={{ color: "red" }}>
              {"-$ " +
                Intl.NumberFormat().format(Math.round(record.spent.price))}
            </Text>
          </Popover>
        ) : (
          ""
        ),
    },
    {
      title: "E. USD",
      dataIndex: "EP",
      key: "ep",
      align: "right",
      hidden: isPM,
      render: (ep) => (
        <Text style={{ color: "#0231E8" }}>
          {ep ? "$ " + Intl.NumberFormat().format(Math.round(ep)) : null}
        </Text>
      ),
    },
    {
      title: "GPM$ (%)",
      dataIndex: "EP",
      key: "ep",
      align: "right",
      hidden: isPM,
      render: (ep, record) => (
        <Text style={{ color: "#0231E8" }}>
          {ep && record.spent.price
            ? `$ ${Intl.NumberFormat().format(
                Math.round(ep - record.spent.price)
              )}`
            : null}
        </Text>
      ),
    },
    {
      title: "Closed Date",
      dataIndex: "ClosedDate",
      key: "ClosedDate",
      align: "right",
      hidden: type !== "closed",
      render: (ep, record) => (
        <Text>
          {record.custom_fields["Closed Date"] &&
            moment
              .unix(record.custom_fields["Closed Date"] / 1000)
              .format("ll")}
        </Text>
      ),
    },
    ...getColumns([MILESTONE_STATUS]),
    // @ts-ignore TODO: fix this
  ].filter((item) => !item.hidden);

  const onChangeSegment = (key) => {
    const timeConst = getTimeConstants();
    console.log("onChangeSegment", key);

    switch (key) {
      case "All":
        setDataPicker(false);
        setMilestonesToShow([...closedMilestones]);
        break;
      case "This Week":
        setDataPicker(false);
        setMilestonesToShow([
          ...closedMilestones.filter((mile) => {
            if (mile.custom_fields["Closed Date"] > timeConst.thisWeek) {
              return mile;
            }
          }),
        ]);
        break;
      case "Last Week":
        setDataPicker(false);
        setMilestonesToShow([
          ...closedMilestones.filter((mile) => {
            if (
              mile.custom_fields["Closed Date"] > timeConst.lastWeek &&
              mile.custom_fields["Closed Date"] <= timeConst.thisWeek
            ) {
              return mile;
            }
          }),
        ]);
        break;
      case "This Month":
        setDataPicker(false);
        setMilestonesToShow([
          ...closedMilestones.filter((mile) => {
            if (mile.custom_fields["Closed Date"] > timeConst.thisMonth) {
              return mile;
            }
          }),
        ]);
        break;
      case "Last Month":
        setDataPicker(false);
        setMilestonesToShow([
          ...closedMilestones.filter((mile) => {
            if (
              mile.custom_fields["Closed Date"] > timeConst.lastMonth &&
              mile.custom_fields["Closed Date"] <= timeConst.thisMonth
            ) {
              return mile;
            }
          }),
        ]);
        break;
      case "Custom":
        setDataPicker(true);
        break;

      default:
        break;
    }
  };

  const onChangeData = (date) => {
    setMilestonesToShow([
      ...milestonesToShow.filter((mile) => {
        if (
          mile.closedDate > moment(date[0]).startOf("day").valueOf() &&
          mile.closedDate <= moment(date[1]).startOf("day").valueOf() + 86400000
        ) {
          return mile;
        }
      }),
    ]);
  };

  return (
    <>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 16,
        }}
        size="small"
      >
        {type === "active" ? (
          <Form.Item label="Flow">
            <Radio.Group
              value={flow}
              onChange={(e) => {
                setFlow(e.target.value);
              }}
            >
              <Radio.Button value="">All</Radio.Button>
              <Radio.Button value="to_start">
                To Start (
                {
                  _.filter(milestones, (mile) =>
                    _.contains(mile.flowTo, "to_start")
                  ).length
                }
                )
              </Radio.Button>
              <Radio.Button value="active">
                Active (
                {
                  _.filter(milestones, (mile) =>
                    _.contains(mile.flowTo, "active")
                  ).length
                }
                )
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        ) : null}
        {type === "closed" && (
          <Space direction="vertical" size="small">
            <Col span={24}>
              <Segmented
                options={[
                  "All",
                  "This Week",
                  "Last Week",
                  "This Month",
                  "Last Month",
                  "Custom",
                ]}
                onChange={onChangeSegment}
              />
            </Col>
            <Col span={24}>
              {isDataPicker ? <RangePicker onChange={onChangeData} /> : null}
            </Col>
          </Space>
        )}
        {(type === "active" || type === "to_close") && (
          <Col span={24}>
            <Form.Item label="Need Sync">
              <Switch checked={sync} onChange={() => setSync(!sync)} />
            </Form.Item>
          </Col>
        )}
      </Form>
      {/* {milestonesToShow?.length ? ( */}
      <Card size="small" title={title}>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          scroll={{ y: 350 }}
          expandable={{
            expandedRowRender: (record) => expandedTasksTable(record),
            rowExpandable: (record) => record.tasks.length,
          }}
          dataSource={milestonesToShow}
          rowKey="id"
          // scroll={{ y: 240, x: 800 }}
          summary={(pageData) => {
            let totalET = 0;
            let totalEP = 0; // Include only projects with spent time
            let totalSpentDirty = 0;
            let totalSpentClean = 0;
            let totalSpentPrice = 0;
            let totalEPDirty = 0; // Include all projects

            pageData.forEach((item) => {
              if (item.spent.dirty) {
                if (item.EP) totalEP += parseFloat(item.EP);
                if (item.ET) totalET += parseFloat(item.ET);
                totalSpentDirty += item.spent.dirty;
                totalSpentClean += item.spent.hours;
                totalSpentPrice += item.spent.price;
              }

              totalEPDirty += item.EP ? parseFloat(item.EP) : 0;
            });

            const gpmUSD = totalEP - totalSpentPrice;
            const gpmPercent = totalEP
              ? Math.round((gpmUSD / totalEP) * 100)
              : 0;

            const COM = totalET / totalSpentDirty;

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    Summary <Text strong>({pageData.length})</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong>
                      {Intl.NumberFormat().format(Math.round(totalSpentDirty)) +
                        " "}
                      | {Intl.NumberFormat().format(Math.round(totalET))}
                    </Text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={5}>
                    <Text strong>{Math.round(totalSpentClean)}</Text>
                  </Table.Summary.Cell>

                  {/* {type === 'toClose' && ( */}
                  <Table.Summary.Cell index={6}>
                    {COM && (
                      <Popover
                        content={getGlobalCOMDetails(totalET, totalSpentDirty)}
                        title="Details"
                        trigger="hover"
                      >
                        <Text strong>{Math.round(COM * 100)}%</Text>
                      </Popover>
                    )}
                  </Table.Summary.Cell>
                  {/* )} */}
                  {isPM ? null :
                  <>
                    <Table.Summary.Cell index={7} align="right">
                      <Text strong style={{ color: "red" }}>
                        {`-$ ${String(Math.round(totalSpentPrice)).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}`}
                      </Text>
                    </Table.Summary.Cell>

                    {/* Totale Estimated Price Including projects without spent time*/}
                    <Table.Summary.Cell index={8} align="right">
                      <Text strong style={{ color: "#0231E8" }}>
                        {`$ ${String(Math.round(totalEPDirty)).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}`}
                      </Text>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={8} align="right">
                      <Text strong style={{ color: "#0231E8" }}>
                        {`$ ${String(Math.round(gpmUSD)).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}`}
                      </Text>{" "}
                      {`(${gpmPercent}%)`}
                    </Table.Summary.Cell>
                  </>
                  }
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Card>
      {/* ) : null} */}
    </>
  );
};

export { MilestonesTableExtended };
