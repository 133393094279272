import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, message } from 'antd';
import { useMapDispatch } from '../hooks/utils.hooks';
import { $user_actions } from '../storage/reducers/user.reducer';
import { useNavigate } from 'react-router-dom';
import { API } from '../helpers/api';

interface IMainPageProps {}

const AuthPage: React.FC<IMainPageProps> = () => {
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const response = await API.auth(values.username, values.password).catch(
      (err) => {
        console.log(err);
        
        message.error(err.response.data?.error.message);
      }
    );

    if (response) {
      if (response.data.user) {
        handleSet(response.data.user);
        API._setBearer();
        if (values.remember) {
          window.localStorage.setItem('jwt', response.data.jwt);
        } else {
          window.localStorage.removeItem('jwt');
        }
        navigate('/');
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleSet = useMapDispatch($user_actions.set);

  return (
    <Row justify='space-around' align='middle' style={{ minHeight: '100vh' }}>
      <Col lg={{ span: 8 }}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label='Username'
            name='username'
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name='remember'
            valuePropName='checked'
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export { AuthPage };
