import React, { useState } from 'react';
import { Button, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { API } from '../helpers/api';

const Reload = () => {
  const [isRealoading, setReloaded] = useState<boolean>(false);

  const togleReload = async () => {
    setReloaded(true);
    const response = await API.reloadTasks().catch((err) => {
      message.error(err.response.data?.error.message);
    });
    setReloaded(false);
    if (response) {
      window.location.reload();
    }
  };

  return (
    <>
      <Button
        icon={
          <ReloadOutlined
            spin={isRealoading ? true : false}
            onClick={togleReload}
          />
        }
      />
    </>
  );
};

export default Reload;
