import counter from './counter.reducer'
import user from './user.reducer'
import projects from './projects.reducer'
import milestones from './milestones.reducer'
import lists from './lists.reducer'
import tasks from './tasks.reducer'
import members from './members.reducer'
import hrs from './hrs.reducer'
import leads from './leads.reducer'

const rootReducer = {
  counter,
  user,
  milestones,
  projects,
  lists,
  tasks,
  members,
  hrs,
  leads
}

export { rootReducer }