import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userDataSelector } from './storage/reducers/user.reducer';
import styles from './app.module.scss';
import { useHeight, useMapDispatch } from './hooks/utils.hooks';
import { PagesRoutes } from './app.router/pages.routes';
import 'antd/dist/antd.css';
import { $user_actions } from './storage/reducers/user.reducer';
import { API } from './helpers/api';
import { $projects_actions } from './storage/reducers/projects.reducer';
import { $milestones_actions } from './storage/reducers/milestones.reducer';
import { $lists_actions } from './storage/reducers/lists.reducer';
import { $tasks_actions } from './storage/reducers/tasks.reducer';
import { $members_actions } from './storage/reducers/members.reducer';
import { $hrs_actions } from './storage/reducers/hrs.reducer';
import { $leads_actions } from './storage/reducers/leads.reducer';

const App: React.FC = () => {
  const user = useSelector(userDataSelector);
  const height = useHeight();
  const [readyFlag, setReadyFlag] = React.useState(false);
  const handleSet = useMapDispatch($user_actions.set);
  const setProjects = useMapDispatch($projects_actions.set);
  const setMilestones = useMapDispatch($milestones_actions.set);
  const setLists = useMapDispatch($lists_actions.set);
  const setTasks = useMapDispatch($tasks_actions.set);
  const setQATasksCount = useMapDispatch($tasks_actions.setQATasksCount);
  const setMembers = useMapDispatch($members_actions.set);
  const setHRs = useMapDispatch($hrs_actions.set)
  const setLeads = useMapDispatch($leads_actions.set)

  React.useEffect(() => {
    const getUser = async () => {
      const user: any = await API.me().catch(() => {});

      if (user) {
        handleSet(user.data);
      }
      setReadyFlag(true);
    };
    void getUser();
  }, []);

  useEffect(() => {
    if (user) {
      API._setBearer();
      API.getQATasks()
        .then((res) => {
          user.position === 'QA' && setTasks(res.data);
          res.data && setQATasksCount(Object.keys(res.data).length)
        })
        .catch(() => {});
      if (user.position === 'QA') {
        API.getQAProjects()
        .then((res) => {
          setProjects(res.data);
        })
        .catch(() => {});

        API.getQAMilestones()
          .then((res) => {
            setMilestones(res.data);
          })
          .catch(() => {});
      } else {
        API.getProjects()
        .then((res) => {
          setProjects(res.data);
        })
        .catch(() => {});

        API.getMilestones()
          .then((res) => {
            setMilestones(res.data);
          })
          .catch(() => {});

        API.getTasks()
          .then((res) => {
            setTasks(res.data);
          })
          .catch(() => {});

        API.getHRs()
          .then((res) => {
            setHRs(res.data);
          })
          .catch(() => {});
      }
      API.getMembers()
        .then((res) => {
          setMembers(res.data);
        })
        .catch(() => {});
      if (user.position === 'SU' || user.position === 'SD' || user.position === "Delivery Manager") {
        API.getLeads()
        .then((res) => {
          setLeads(res.data);
        })
        .catch(() => {});
      }
    }
  }, [user]);

  return (
    <div style={{ height }} className={clsx(styles.root)}>
      {readyFlag && <PagesRoutes />}
    </div>
  );
};

export { App };
