
import axios from 'axios';
import { 
    API_HOST, 
    CURRENT_USER, 
    PROJECTS, 
    USER_CONNECT, 
    MILESTONES, 
    ASSOCIATED_LIST, 
    TASKS,
    RELOAD,
    MEMBERS,
    QA_PROJECTS, QA_MILESTONES, QA_TASKS,
    HR,
    LEADS
 } from '../constants/api.constants'

 axios.defaults.baseURL = API_HOST;
 const ax = axios.create();
 
const API = {
    _setBearer: () => {
        ax.defaults.headers.common = { 'Authorization': `bearer ${window.localStorage.getItem('jwt')}` }
    },

    auth: async (login, password) => {
        const formData = new FormData();
        formData.append('identifier', login);
        formData.append('password', password);
        return axios.post(USER_CONNECT, formData, { 
            headers: { 'Content-Type': 'multipart/form-data' } 
        });
    },

    me: () => {
        const jwt = window.localStorage.getItem('jwt');
        return ax.get(CURRENT_USER, { headers: { 'Authorization' : `Bearer ${jwt}` } })
    },

    getProjects: () => {
        return ax.get(PROJECTS)
    },

    getMilestones: () => {
        return ax.get(MILESTONES)
    },

    getAssociatedLists: () => {
        return ax.get(ASSOCIATED_LIST)
    },

    getTasks: () => {
        return ax.get(TASKS)
    },

    getQAProjects: () => {
        return ax.get(QA_PROJECTS);
    },

    getQAMilestones: () => {
        return ax.get(QA_MILESTONES)
    },

    getQATasks: () => {
        return ax.get(QA_TASKS)
    },

    reloadTasks: async () => {
        return ax.get(RELOAD)
    },

    getMembers: () => {
        return ax.get(MEMBERS)
    },

    getUserJWT: async (login, password='nan') => {        
        const formData = new FormData();
        formData.append('identifier', login);
        formData.append('password', password);
        formData.append('jwt', `${window.localStorage.getItem('jwt')}`);
        return axios.post(USER_CONNECT, formData, { 
            headers: { 'Content-Type': 'multipart/form-data' } 
        });
    },

    getHRs: () => {
        return ax.get(HR)
    },

    getLeads: () => {
        return ax.get(LEADS)
    }
}

declare global {
    interface Window {
        API:any;
    }
}

export { API };